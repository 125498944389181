.container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 1.2rem;
  background: #fff;
}

.header{
  color: white;
  border-radius: 1.4rem;
  padding: 1rem 1rem .2rem;

  display: flex;
  flex-direction: column;

  .legend{
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
  }
  .navbar{
    display: flex;
    justify-content: space-around;
    align-items: flex-end;

    .headerIcon{
      width: 2.3rem;
      height: 2.3rem;
      border-radius: 50%;
      object-fit: cover;
    }
    .disabled .headerIcon{ opacity: .7; }
    .headerLogo{      
      a{
        position: relative;
        margin-bottom: -.2rem;
        padding: 1rem 2rem .25rem;
        display: flex;
        svg{
          position: absolute;
          z-index: 0;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
        }
        img{
          position: relative;
          width: 3rem;
          z-index: 1;
          margin-top: auto;
        }
      }
    }
  }
}

.footer{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: .5rem 1rem;
  color: #fff;
  text-align: center;
  min-height: 2.2rem;
}

.modalSelectClient{
  z-index: 999;
  position: fixed;

  .mscContent{
    header{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .modalTitle{
        margin: 0;
        font-size: 2rem;
      }
      .modalSubtitle{
        opacity: .7;
      }
      .btnClose{
        border: 0;
        padding: 0;
        background: transparent;
        margin:  .4rem 0 0;
        cursor: pointer;
        transition: .2s;
    
        &:hover{ opacity: .7; }
      }
    }

    .mscClientsContainer{
      display: grid;
      margin-top: 1.5rem;
      gap: 1rem;
      
      // grid-template-columns: repeat(4, 1fr);

      // @media(max-width: 1200px){
        grid-template-columns: repeat(3, 1fr);
      // }
      @media(max-width: 800px){
        grid-template-columns: repeat(2, 1fr);
      }
      @media(max-width: 600px){
        grid-template-columns: repeat(1, 1fr);
      }

      .mscClientItem{
        display: flex;
        flex-direction: column;
        padding: 1rem .8rem;
        border-radius: .4rem;
        background: #fff;
        box-shadow: 0 0 20px #0022;
        cursor: pointer;
        transition: .6s;

        & > * { word-break: break-word; }
        .mscciTitle{ font-size: 1.2rem; }
        .mscciSubtitle{ opacity: .8; }
        .mscciLegend{
          opacity: .5;
          font-size: .8rem;
        }

        &.active{
          background: #2B64B3;
          color: #fff;
        }
        &:hover{
          filter: brightness(.9);
        }
      }
    }
  }
}