.content{
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  margin-bottom: 2rem;
}
.aside_actions{
  margin-bottom: 1rem;

  @media (max-width: 1200px){
    display: flex;
    gap: 1rem;
  }
}
// BEGIN:: ADVANCED FILTER
.cardFilters{
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 60px #0022;
  border-radius: 20px;

  .filtersTitle{
    font-weight: 600;
    font-size: .75rem;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: block;
    margin: -.4rem 0 .4rem;
  }
  .normalFilter{
    @media (max-width: 1200px){
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
  .advanced_filter{
    margin: 1rem 0;
  
    @media (max-width: 1200px){
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
}
// END:: ADVANCED FILTER

.container__cards{
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  padding: 0 0 10px;
  width: 100%;
  
  @media(min-width: 1201px){
    padding-left: 10px;
  }
}

.table__container{
  width: 100%;

  padding: 20px;
  background-color: white;
  box-shadow: 0 0 60px #0022;

  border-radius: 20px;
  min-height: 20rem;
}